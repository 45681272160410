import React, { Component } from 'react'
import Navbar from './components/Navbar'
import image71 from './components/Images/image71.webp'
import Whatsapp from './components/Whatsaap'
import Footer from './components/Footer'

export default class Fear extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image71} alt="image71" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "30px" }}>I AM A 28 YEAR OLD FEMALE, AND I HAVE REACHED THIS WITHOUT DEVELOPING BREASTS!</strong></span>
                        <p>
                            My name is Aisha, a resident of Tabora town. I am a very beautiful girl, and I say this because every time I walk by, men turn their heads to look at me, sometimes due to my figure, color, and overall appearance.
                            <br />
                            So, I was always asked for my phone number, with each person giving compliments and asking me to be their wife. The problem was that every man I accepted was surprised because I didn't have large breasts like other women.
                            <br />
                            Rumors started spreading in our neighborhood that I had a dual gender, and some said that I wasn't a woman like other women.
                            <br />
                            It reached a point where I started fearing and asking myself many questions about why I had everything else but lacked breasts, especially when many girls get them as soon as they hit puberty.
                            <br />
                            One day, while at my shop, a very wealthy man came and bought three trousers and two shirts. After selling him the clothes, he got into his car and left. After a few days, he came back to my shop, bought shoes, and asked for my phone number.
                            <br />
                            I gave him my number, and we started communicating regularly. He expressed his desire to marry me if I was willing, and I agreed. Thus, our romantic relationship began.
                            <br />
                            Our relationship grew over time, but I didn't give him the chance to meet me physically because I feared he might react like the others I had encountered before.
                            <br />
                            One day, he came to my house as I had requested, and the wedding day was planned. However, my heart was pounding fast every time I thought about the day we would share a bed, and he would discover I didn't have breasts.
                            <br />
                            As the wedding day approached, I had to open up to my friend Hadija about my situation. She told me about Bakongwa Doctors, who had helped her aunt reduce the size of her stomach. Hadija took her phone, called her aunt, and got me in touch with Bakongwa Doctors using their phone number, +254796703069.
                            <br />
                            I messaged Bakongwa Doctors on WhatsApp, explaining my situation. Within a few minutes, they responded and explained how I could get treatment and the time it would take to see results.
                            <br />
                            On the third day, following Bakongwa Doctors' instructions, I began to see changes in my breasts. They increased every day until I reached the desired size. I informed Bakongwa Doctors that it was enough.
                            <br />
                            Finally, the wedding day arrived, and I married my beloved. I am now in my marriage with two sons and a daughter.
                            <br />
                            Bakongwa Doctors also have medicines for job opportunities, male potency, bringing back your luck, managing diabetes, and quitting alcohol in just seven days.</p>
                        <strong>Connect with Us:<a href="tel:+254796703069" className="green-text text-darken-2" > Phone : +254796703069</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}