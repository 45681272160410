import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image84 from './components/Images/image84.jpg'
import Footer from './components/Footer'

export default class JobSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image84} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text"><strong style={{ fontSize: "25px" }}>HOW DR BAKONGWA HELPED US FROM SCAMMERS AND INHERITANCE ISSUES.</strong></span>
                        <p>
                            They wanted to take away our inherited house, but we won the case!

                            My name is Yamola, and I am 20 years old, the firstborn in our family of eight boys and five girls.

                            In 2017, our parents died in a car accident, causing immense sorrow. I was still in college, and my younger siblings were too young, some just starting their education.

                            After the burial, some relatives promised to help us pay school fees and provide living expenses, but it didn't happen that way.

                            We went through tough times, with my younger siblings eventually unable to attend school due to the lack of food at home. Hunger became a common occurrence in our lives.

                            I decided to work while continuing my college studies, sharing whatever little I earned with my siblings. In the midst of these hardships, an older relative claimed that the house we lived in was his by family traditions, stating that the eldest brother of the deceased inherits everything.

                            I was surprised by this, and the next day, we were evicted from the house, our belongings thrown outside. We had to live with other relatives and friends of our parents.

                            A friend gave me the contact number of Bakongwa Doctors, +254796703069, and suggested that I call him as he could help me win the court case regarding this matter.

                            I contacted Bakongwa Doctors and explained my situation. He assured me not to worry, claiming he had helped many people win significant cases in their lives.

                            I filed the case, and everyone doubted I could win, especially as I had no money. The case took seven months, and to everyone's surprise, the judgment ruled in my favor. We were able to return to our house, and the tenants my older relative had placed there demanded their rent with a lot of disputes, but he eventually paid them.

                            Many thanks to Bakongwa Doctors for your service. You are indeed an essential person in our community. Without you, we wouldn't have a place to live now.

                            Remember, Bakongwa Doctors has the ability to bring luck into someone's life, win lottery games, protect you from your enemies, help you win conflicts like land disputes, have romantic appeal, and increase sales in business.

                            He also treats diseases like syphilis, gonorrhea, tuberculosis, high blood pressure, epilepsy, erectile dysfunction, swollen legs, among others. Additionally, he helps women conceive and men impregnate women.</p>
                        <strong>Connect with Us:<a href="tel:+254796703069" className="green-text" > Phone : +254796703069</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}