import React, { Component } from 'react'
import Navbar from './components/Navbar'
import Whatsapp from './components/Whatsaap'
import image6 from './components/Images/image6.jpg'
import Marquee from 'react-fast-marquee'
import image21 from './components/Images/image21.jpg'
import image22 from './components/Images/image22.jpg'
import image23 from './components/Images/image23.jpg'
import image24 from './components/Images/image24.jpg'
import image25 from './components/Images/image25.jpg'
import image26 from './components/Images/image26.jpg'
import image27 from './components/Images/image27.jpg'
import image28 from './components/Images/image28.jpg'
import image29 from './components/Images/image29.jpg'
import image30 from './components/Images/image30.jpg'
import image31 from './components/Images/image31.jpg'
import image32 from './components/Images/image32.jpg'
import { Link } from 'react-router-dom'
import image33 from './components/Images/image33.jpg'
import image34 from './components/Images/image34.jpg'
import image35 from './components/Images/image35.jpg'
import image36 from './components/Images/image36.jpg'
import image37 from './components/Images/image37.jpg'
import Footer from './components/Footer'

export default class Aboutus extends Component {

     componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <Whatsapp />
                <div className="container">
                    <div className="grey lighten-5">
                        <h3 className="center green-text text-darken-2" > ABOUT US</h3>
                        <div className="row">
                            <div className="col l3 s12 m12 offset-l2">
                                <p className="black-text">
                                    Bakongwa Doctors are the Best Traditional African Herbalists from Tanzania, and the Spiritual Healers, who not only deliver to their promises but even heal the unhealed diseases and solve all solutions.
                                    With the exceptional working Knowledge, Bakongwa Doctors turn your problems to a permanent solutions.
                                    You don’t even need much but just a call and get your problems solved.
                                </p>
                            </div>
                            <div className="col l4 s12 m12 offset-l3 offset-s3 offset-m4">
                                <img src={image6} alt="Dr.Bakongwa" className="col s10 pull-s2 materialboxed" />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <a href="https://wa.me://+254796703069" className="waves-effect waves-light btn-large col s6 offset-s3 green darken-2">CALL BAKONGWA NOW</a>
                        </div>
                        <Marquee speed="63" direction="right" > <h5 className="center green-text text-darken-2" style={{ fontSize: "30px" }} >BAKONGWA DOCTORS DEALS WITH</h5></Marquee>
                    </div>
                    <div className="row">
                        <div className="col s10 m10 l10 push-l1 push-s1 push-m1" >
                            <div className="card row">
                                <div className="card-image col s12 m12 l4">
                                    <img src={image21} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Love Problems</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image22} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Financial Problems</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image23} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Pass Job Interviews</span>
                                </div>
                            </div>
                            <div className="card row">
                                <div className="card-image col s12 m12 l4">
                                    <img src={image26} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Education</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image25} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Marriage Problems</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image24} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Family Curses</span>
                                </div>
                            </div>
                            <div className="card row">
                                <div className="card-image col s12 m12 l4">
                                    <img src={image27} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Enemis</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image28} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Penis Elargment</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image29} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Work Related Problems</span>
                                </div>
                            </div>
                            <div className="card row">
                                <div className="card-image col s12 m12 l4">
                                    <img src={image30} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Barrenness</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image31} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Witchft Matters</span>
                                </div>
                                <div className="card-image col s12 m12 l4">
                                    <img src={image32} className="container materialboxed" alt="financialprobles" />
                                    <span className="card-title white-text">Nightmares</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="center">
                        <Link to='/ContactUs' className="btn-small green darken-2">Request Help Now</Link>
                    </div><br />
                    <h5 className="green-text text-darken-2 center" style={{ 'fontSize': "40px" }} ><b>WORDS FROM OUR LAST PATIENTS</b></h5>
                    <div className="row">
                        <div className="col s10 m10 l10 offset-l1 push-s1 push-m1">
                            <div className="card-panel row">
                                <div className="col s12 m12 l4">
                                    <span>"I highly recommend the Bakongwa doctors to you for whatever problems you are experiencing whether is love, protection, money or psychic powers. Bakongwa Doctors are the real deal. When I had over a thousand and one problems, Bakongwa Doctors solved then in a span of Minutes. Thank you so Much Bakongwa Doctors!"</span><br />
                                    <i className="green-text text-darken-2">Wollen - Tanzania</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"I have had amazing things happen during the first week of my casting. Jason has done a 360 and is now talking with me and wanting to spend time with me again. I can't believe after all that has happened he actually turned around and started talking and wanting to be with me. It's AMAZING!  You are like a god sends an angel in my life. What can I say? Veronica and he just split up and he wants now to be with me. Thank you very much for your help Bakongwa Doctor."</span><br />
                                    <i className="green-text text-darken-2">Celine - Uganda</i>
                                </div>
                                <div className="col s12 m12 l4">
                                    <span>"My neck doesn't hurt any more" I am forever grateful for your help Doctor Bakongwa! Kindness and patience is with me. The pain I was feeling in my neck completely disappeared like you had promised me and I don't feel all that negative energy around me. You are the best Doctor Bakongwa! Thank you thank you, thank you, a thousand times."</span><br />
                                    <i className="green-text text-darken-2">Vidya - Tanzania</i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to='/ContactUs'><h5 className="green-text text-darken-2 center" style={{ 'fontSize': "30px" }} ><b>ARE YOU TIRED FROM ENDLESS PAIN ?</b></h5></Link>
                    <div className="row">
                        <div className="col s12 m12 l6">
                            <h3 className="green-text text-darken-2 center">Barreness</h3>
                            <p>Getting pregnant and carrying a pregnancy to term are actually very complicated processes. Many things can go wrong during these processes to lead to infertility. For this reason, women at child bearing age get tortured psychologically. Since it is joyous once a woman realizes that she has conceived, a woman who is having difficulty getting pregnant or carrying a pregnancy to term can talk to Dr. Bakongwa about possible treatments. Book an appointment today.</p>
                        </div>
                        <div className="col s12 m12 l6">
                            <img src={image33} alt="Barrenness" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l6">
                            <h3 className="green-text text-darken-2 center">Divine Future</h3>
                            <p>It seems humans have for a very long time been troubled by the opacity of the future. They'd like to have a better sense of what lies ahead, and they've come up with some ingenious ways of trying to get at that information. Divination is the art or practice that seeks to foresee or foretell future events, or to discover hidden knowledge. Divination usually involves the interpretation of omens or, if you're really lucky, the assistance of supernatural powers. Let us know if any of these work for you once you get a divination spell from Dr. Bakongwa.</p>
                        </div>
                        <div className="col s12 m12 l6">
                            <img src={image34} alt="Barrenness" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l6">
                            <h3 className="green-text text-darken-2 center">Win Court Cases</h3>
                            <p>Court Case spells are used to solve any type of court case or legal matter problems. People are accused of things they never do and others did but need a retreat. Come to me and get a court spell you can use to win any kind of case whether you are guilty or not. If you are suffering and tired and the case or any legal matter that you are going through is not ending, then you may contact me for a very strong court case spell and you overcome it.</p>
                        </div><br /><br />
                        <div className="col s12 m12 l6">
                            <img src={image35} alt="Barrenness" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l6">
                            <h3 className="green-text text-darken-2 center">Magic Rings</h3>
                            <p>Magic rings are powerful rings that have been cast with a spell. A magic ring contains the spiritual energy of the spell that has been cast on it. A magic ring for love is a love spell that is attached to a ring. A magic ring for money is a money spell attached to a ring. A magic ring for protection is a protection spell attached to a ring. Shield your aura from any spiritual harm with magic rings. You can also use magic rings for healing spiritual & physical ailments to eliminate the root cause of sickness & disturbance. Magic rings have different spiritual properties depending on the type of spell cast on the globe .Contact Dr. Bakongwa now to get your magic ring.</p>
                        </div>
                        <div className="col s12 m12 l6">
                            <img src={image36} alt="Barrenness" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12 l6">
                            <h3 className="green-text text-darken-2 center">Family & Relationship Problems</h3>
                            <p>All relationships and families go through difficult times and experiencing occasional problems and conflict in relationships is normal. However, sometimes these problems can become overwhelming. Bakongwa Doctor is a relationship and marriage counsellor based in Kisumu, Kenya. Book an appointment today with the doctor if you have relationship and marriage problems.</p>
                        </div><br /><br />
                        <div className="col s12 m12 l6">
                            <img src={image37} alt="Barrenness" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}