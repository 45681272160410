import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image86 from './components/Images/image86.jpg'
import Footer from './components/Footer'

export default class PenisSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image86} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>Know the simple strategies for winning a court case thoroughly.</strong></span>
                        <p>
                            Due to some dishonesty among the judiciary officials and their willingness to accept bribes, some people have been unable to receive the justice they deserve in this crucial institution.

                            In this world, there are individuals known for using their money to bribe lawyers and judges to win cases, and they have succeeded to a large extent.

                            My name is Hussein, and I live in Nakuru, Kenya. About two years ago, I was employed at a cement production factory, a job that was extremely challenging, leading many young people to quit regularly.

                            I endured it because I had serious financial problems. My life was very tough, and my family depended on me so much that I had no choice but to keep working.

                            After finishing my first month at work, I was surprised to receive only half of my payment. When I inquired, I was told that the remaining amount would be paid the following month.

                            However, the next month, I was not paid at all. I asked some of my coworkers if they had received their salaries, and they said no.

                            Upon further investigation, I discovered that the office had a habit of not paying its employees. Even if you took the matter to court, they would use bribery, and the plaintiff wouldn't get anything, and you would be seen as the wrongdoer.

                            I told my friend about this, and he advised me to contact someone named Bakongwa Doctors; he would help me when I filed a case in court to claim my rights. He gave me Bakongwa Doctors' number, +254796703069, and I decided to contact him and explain the whole situation.

                            After talking to him on the phone, he assured me that I would win the case without any difficulty. So, I filed the case, and my coworkers were surprised. Some said I wouldn't get anything because many had tried and failed.

                            The case started and took six months to conclude. I can't forget the moment the judge declared me the winner and ordered the company to pay all my money along with compensation. Many people couldn't believe how I confidently presented my case in court; the secret to my victory was Bakongwa Doctors.

                            My friends came to congratulate me and said I had shown remarkable courage, astonishingly removing fear from many young people. I told them that when they feel unjustly treated anywhere, they shouldn't hesitate to go to the legal system because help is readily available.

                            Remember, Bakongwa Doctors has the ability to bring luck into someone's life, win lottery games, protect you from your enemies, help you win conflicts like land disputes, have romantic appeal, and increase sales in business.

                            He also treats diseases like syphilis, gonorrhea, tuberculosis, high blood pressure, epilepsy, erectile dysfunction, swollen legs, among others. Additionally, he helps women conceive and men impregnate women.</p>
                        <strong>Connect with Us:<a href="tel:+254796703069" className="green-text text-darken-2" > Phone : +254796703069</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}