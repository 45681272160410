import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import image88 from './components/Images/image88.jpg'

export default class Miscariage extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <main>
                <Navbar />
                <Whatsapp />
                <div className="grey lighten-5 row">
                    <br />
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image88} className="materialboxed col s12 m12" style={{ width: "100%" }} alt="miscariage" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>GET TO FIND SOMEONE YOU GENUINELY LOVE EACH OTHER.</strong></span>
                        <p>
                            I compare love to a garden that requires constant watering. Without sufficient water, it may wither and eventually die. Love needs the water of affection every day to flourish.

                            My name is Maria, a resident of Kinondoni. I began my journey with love at the age of 20, and my first boyfriend was named Baraka, although I called him Mwalimu (Teacher) because he taught me everything about love.

                            My first experience with intimacy was painful, and I swore never to be with a man again due to the pain I endured. However, after a few days, the memories of Mwalimu's words lingered in my mind, compelling me to call him and suggest we continue our relationship without the physical aspect.

                            Mwalimu agreed, and we resumed meeting at various places. During these encounters, I found myself desiring intimacy again. We decided on a day to meet for the act, and the experience was different, and the pain was less compared to the first time. As time passed, my desire for intimacy grew, and I willingly participated in the act.

                            After three years in the relationship, Mwalimu started changing, and I discovered he was involved with another girl named Judith. This news disturbed me, and I began to harbor resentment towards men, feeling that they were insensitive, especially in matters of love.

                            Despite enduring this situation, I eventually decided to move on with my life. After six months, I entered into a new relationship with a young man named Aivan. Aivan loved me dearly, but whenever I was with him, thoughts of Mwalimu consumed me, even mentioning his name during intimate moments. Eventually, Aivan left, suspecting I had someone else in my life when he wasn't around.

                            My heart was restless, and I sought help from a close friend who recommended Bakongwa Doctors, known for their ability to make someone return within 48 hours. The friend mentioned that Bakongwa Doctors had helped her younger sister find a husband after years of being unmarried.

                            I searched for Bakongwa Doctors' contacts and found their website, www.Bakongwa-doctors.com, along with their phone number, +254796703069. I contacted Bakongwa Doctors, explained my situation, and requested assistance, as my heart couldn't forget Mwalimu.

                            During the conversation, I informed Bakongwa Doctors that I couldn't physically reach them but needed help because I wasn't ready to see Mwalimu marry another woman. Bakongwa Doctors provided guidance, asked a few questions about Mwalimu, and assured me to wait for 48 hours. Mwalimu indeed contacted me, admitting his mistakes and expressing a desire to restore our relationship. I forgave him, and now we live together as husband and wife.
                        </p>
                        <strong>Connect with Us:<a href="tel:+254796703069" className="green-text text-darken-2" > Phone : +254796703069</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </main>
        )
    }
}