import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image73 from './components/Images/image73.jpg'
import Footer from './components/Footer'

export default class Married extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    

    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image73} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>SHE FINALLY GOT MARRIED, SHE HAD BEEN EAGERLY WAITING FOR THIS OPPORTUNITY.</strong></span>
                        <p>
                            A certain Kenyan lady once elicited netizens after she had been seen walking with a banner which read “I need someone to marry me as I’m aging” most people especially of the male gender saw this as really ridiculous as it was not the usual state seeing a lady seek for a husband in the open. Some men just took photos of her but never made any approach of trying to talk to her for some marriage relationship as most they thought she had been undergoing some mental disorder.
                            As she narrated, her age was not on her side and she kept on wondering when was she going to get the right person at least for a marriage and be like other women who were close to her. She added that she had the habit of neglecting each particular man who came into her contact without knowing one day she was to need the men for a marriage. Her age had really gone as she said it was only one year and she was to turn 40 years of age but a potential husband was nowhere to be seen.
                            Through various people, she was able to get Dr Bakongwa contacts where she visited him for any possible assistance as she was informed how Dr Bakongwa was a distinguished spell caster who would help her get her desirable husband despite her age. Dr Bakongwa had his spells to her. A week after having met Dr Bakongwa, it was really some good news as she had got her perfect match who was a teacher at a certain local school some few kilomitres from where she lived. This was a beginning of a new dawn to her life as really Dr Bakongwa had ensured that her life had really changed and now she had a husband. They lived together where now the lady is happily preganant. Good work to Dr Bakongwa spells as the lady now feels just like any other woman in a successful marriage. Dr Bakongwa spell casting powers always work within 24 hours, mostly happening the same day they are released. He solves a range of life problems starting from family life problems like domestic violence, jealousy among relatives, casting out demonic spirits and more others. Contact Dr Bakongwa as he treats ulcers, TB, syphilis, manhood weaknesses among other things just within 24hours. Do not hesitate to contact him in case you find yourselves in such problems</p>
                        <strong>Connect with Us:<a href="tel:+254796703069" className="green-text text-darken-2" > Phone : +254796703069</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}