import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image82 from './components/Images/image82.webp'
import Footer from './components/Footer'

export default class LuckCharmSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image82} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>HOW MY FIRST JOB INTERVIEW GAVE ME A WELL PAYING JOB AS THE BANK MANAGER IN ONE BIG BANKS IN UGANDA.</strong></span>
                        <p>
                            Iam a graduate with degree with lots of hope ahead of me was going to be good having worked really  hard in my studies I wanted to see the fruits back
                                <br />
                            • Before joining university I passed highly with a good grade that landed me to do banking and  management a course I was so passionate about it was going to change my life indeed.
                            <br />
                            I had  been brought in a humbled background and by means of well wishers the contribution also  made me going my thought was that if I finished going to school I will be employed immediately until I learnt that in kenya when there is no connection bribery your degree will be useless my  parents and siblings started to count on me cause they knew that once somebody graduated  they got a job immediately .
                            <br />
                            I tried searching for job going for different interviews without  getting one.When I vacated the school premises I had nowhere to go to no money to rent my  own house even to buy a one day food only I was left with only one choice that is to be a street  of Gulu with no hopes of life I was moving from one street to another just to pass time when  night came I do roll myself in a sack under flyovers during the days I have been visiting  construction sites where I get casual job that I earned 400shilings a day .
                            <br />
                            one day as I was in  construction job doing my normal chores I saw a friend of mine who was my school mate driving  an expensive car he came to the site to inspect the work done little did I know he was the owner  of the mansion I ran to him and greated him he was shocked to see me work there despite my  qualification after a long chat I asked him how he got that a lot of money in a twinkle of an eye he told me that doctor Bakongwa that is gifted in casting powerful spells had helped him that  doctor Bakongwa casted luck spell in her life that the kuomoka in that short of period is all  Bakongwa's juju he gave me doctor’s contact and ask him to change my life without hesitating
                            <br />
                            I  contacted them booked an appointment and meet him in person he told that I was being  haunted by bad luck from our family he performed his juju on me and casted good luck in me A  day after meeting the doctor I got a message that requested me to go to an interview in a bank and that’s how I was employed as a bank manager indeed my life has changed my family status  is changed
                            <br />
                            Thank you doctor Bakongwa and that my friend who gave me the advice you people  helped my life a good favour deserves another if you have any problem not only getting job Bakongwa is casting different spells in human life he is also gifted in treating and healing using  African traditional herbs
                        </p>
                        <strong>Connect with Us:<a href="tel:+254796703069" className="green-text text-darken-2" > Phone : +254796703069</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}