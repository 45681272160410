import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image77 from './components/Images/image77.jpg'
import Footer from './components/Footer'

export default class WitchCraftSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image77} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>HE TOOK OFF MY UNDERWEAR SO THAT I WOULDN'T BE MARRIED AFTER REJECTING HIM.</strong></span>
                        <p>
                            James was a young man who lived in our neighborhood of Buza. We got to know each other and met regularly. One day, as I was leaving the Buza Stand to go home, I encountered James, and he asked for my phone number. Without hesitation, I gave it to him.

                            That night, James called me and asked if we could be in a romantic relationship. His words shocked me because he was known for having a bad reputation in our neighborhood.

                            I couldn't agree to his request due to his behavior, and he didn't possess qualities I admired. After rejecting him, it became a struggle for me. He would send his friends to taunt me and say nasty things about me. At times, he even resorted to violence, including physical assault.

                            One day, I washed and hung my clothes, including underwear, outside. After a few hours, when I went to check, I was surprised to find that one of my underwear pieces was missing from the line.

                            I didn't investigate much, as I didn't think someone would take my underwear. The next day, around 5 o'clock in the morning, my grandmother sent me to the store. On the way, I met Alex, who was one of James's friends.

                            Alex stopped me and handed me a black bag, saying it was a message from James. Initially, I refused to accept it, but Alex insisted, so I opened it immediately and found my underwear cut into pieces.

                            I didn't even go to the store; I turned back home quickly. Before reaching home, I encountered James and two of his friends. James then said, "I swear you will never get a husband in your life; you belong to me."

                            I didn't respond, but I went to tell my grandmother and prayed to God to protect me from James's incidents because I had reached the point of fearing to go outside.

                            After four years, all my peers got married, and James found a woman. However, I couldn't find anyone even to propose to me. When I turned 30, I started questioning why I wasn't getting married, and I remembered James's words and wondered why he stole my underwear and tore it into pieces.

                            At 32, without a husband and child, I became filled with worry and asked myself why it was happening to me. While on social media, I came across a testimony of someone helped by Bakongwa Doctors to get a job promotion.

                            I took the number of Bakongwa Doctors from the testimony and decided to contact them. I explained my major problem of not finding a man despite my age.

                            After an hour passed, I called Bakongwa Doctors. They listened to my life story and the obstacles I faced, including the underwear incident when I was 20. Bakongwa Doctors promised to help me with Love Spells, and within four days, I would see positive results.

                            On the third day, in the morning, I found someone I desired to be with, and through the power of Bakongwa Doctors, today, he is in my arms in marriage.

                            In our conversation, Bakongwa Doctors mentioned that they treat people wherever they are and solve other challenges, such as reuniting with a partner, winning lottery games, and resolving family issues.</p>
                        <strong>Connect with Us:<a href="tel:+254796703069" className="green-text text-darken-2" > Phone : +254796703069</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}