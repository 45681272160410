import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image83 from './components/Images/image83.jpg'
import Footer from './components/Footer'

export default class LuckCharmSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image83} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>Strategies to  make children succeed in their studies.</strong></span>
                        <p>
                            The truth is that every parent, when taking their child to school, expects them to perform well in their studies and pass exams so that they can progress and eventually pursue a certain profession that will help them in their life.

                            You always know that nothing challenges most parents like when they spend a lot of money to pay their child's school fees, but the child keeps failing all the time. This is a very painful and undesirable challenge.

                            I say this because it has happened to me in my life where my firstborn, from the first grade to the fourth, was performing very poorly in his studies.

                            Until today, I want to specially thank Bakongwa Doctors, whose contact I got through a fellow parent whose child was excelling in studies to the point that teachers praised him.

                            After asking for the secret to his child's success in studies, he mentioned Bakongwa Doctors as the one who provided him with medicine to enable his child to excel in studies all the time.

                            So, I was able to contact this expert in herbal medicine, and personally, I am grateful for his service because since then, my child has had good results in their exams.

                            Bakongwa Doctors sent me medicine with instructions on how to use it for my child, and slowly, my child started coming home and showing me how well he was doing in the daily tests given by the teachers, to the point that even his classmates were amazed because they were used to him always failing.

                            When it came to the final exams, my child was among the top 10 students in their school.

                            I remember from that time onwards; he continued to excel, and now he is in his second year of university. Thank you so much, Bakongwa Doctors, for your medicine that I will never forget.

                            Remember, Bakongwa Doctors has the ability to bring luck into someone's life, win lottery games, protect you from your enemies, help you win conflicts like land disputes, have romantic appeal, and increase sales in business.
                        </p>
                        <strong>Connect with Us:<a href="tel:+254796703069" className="green-text text-darken-2" > Phone : +254796703069</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}