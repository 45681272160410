import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image80 from './components/Images/image80.jpg'
import Footer from './components/Footer'

export default class EducationSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image80} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text tet-darken-2"><strong style={{ fontSize: "25px" }}>I was able to get pregnant after struggling for a long time..</strong></span>
                        <p>
                            In my life, I struggled for many years to conceive. I visited every hospital, tried all kinds of medicines, especially herbal remedies, but I couldn't get pregnant.

                            My name is Anna, and I got married seven years ago. Initially, we were deeply in love with my husband to the point that even our neighbors admired our marriage. However, two years into the marriage, things began to change, especially on my husband's part, due to my inability to conceive during that time.

                            He openly expressed his desire for a child and made it clear that if I couldn't fulfill that, we would part ways anytime. I was constantly in tears, questioning what my fault was, as everyone has their own time for success in life.

                            What hurt me even more were the words from my sisters-in-law and my mother-in-law, claiming that I was just using their brother's money without any success.

                            I finally found relief after one day visiting the Bakongwa Doctors' website (www.bakongwadoctors.com.com), which indicated that they provide services to help women conceive.

                            I quickly took their contact number, reached out, and explained my problem, which was jeopardizing the safety of my marriage in the face of society.

                            I'm glad they listened attentively, and we agreed that I should visit their office in Kericho. After a few days, I went to meet them, and they provided me with medication along with valuable advice that eventually bore fruit.

                            After three weeks, I woke up feeling nauseous, so I decided to visit the hospital for a checkup. It turned out I was pregnant, and I can't forget the immense joy I felt that day.

                            I went back home, eagerly waiting for my husband to return from work. When he came back, I told him about the pregnancy, a fact he himself found hard to believe. We had to go to the pharmacy to take another test to confirm, and that's when he truly believed that I was pregnant.

                            Now, I am a mother of two, and I am very grateful to Bakongwa Doctors for their life-changing treatment that brought back smiles to my family.

                            Remember, Bakongwa Doctors have the ability to bring luck into someone's life, win lottery games, protect you from enemies, help you win conflicts like land disputes, have a romantic allure, and increase sales in business.






                            .</p><br /><br />
                        <strong>Connect with Us:<a href="tel:+254796703069" className="green-text tet-darken-2" > Phone : +254796703069</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text tet-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}