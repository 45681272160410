import React, { Component } from 'react'
import Navbar from './components/Navbar'
import image8 from './components/Images/image8.jpg'
import image9 from './components/Images/image9.jpg'
import Whatsapp from './components/Whatsaap'
import image38 from './components/Images/image38.jpg'
import image39 from './components/Images/image39.jpg'
import image40 from './components/Images/image40.jpg'
import image41 from './components/Images/image41.jpg'
import image42 from './components/Images/image42.jpg'
import image43 from './components/Images/image43.jpg'
import image44 from './components/Images/image44.jpg'
import image45 from './components/Images/image45.jpg'
import image46 from './components/Images/image46.jpg'
import image47 from './components/Images/image47.jpg'
import image48 from './components/Images/image48.jpg'
import image49 from './components/Images/image49.jpg'
import image50 from './components/Images/image50.jpg'
import image51 from './components/Images/image51.jpg'
import Footer from './components/Footer'

export default class Magicrings extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }

    
    render() {
        return (
            <React.Fragment>
                <Navbar />
                <Whatsapp />
                <div className="grey lighten-5 container ">
                    <div className="row">
                        <div className="col l6 m12 s12">
                            <h5 className="green-text text-darken-2 center"><b>MAGIC RINGS</b></h5>
                            <p>A magic ring is a piece of jewelry, usually a finger ring, that has supernatural properties or powers. Magic rings are found in every country where rings are worn. Some magic rings can endow the wearer with a variety of abilities including invisibility and immortality. Others can grant wishes or spells such as never ending love and happiness. More often, however, they are featured as forces for good, or as a neutral tool whose value is dependent upon the wearer. A finger ring is a convenient choice for a magic item: it is ornamental, distinctive and often unique, a commonly worn item, of a shape that is often endowed with mystical properties (circular), can carry an enchanted stone, and is usually worn on a finger, which can be easily pointed at a target.</p>
                        </div><br /><br />
                        <div className="col l6 s12 m12">
                            <img src={image8} alt="Magic Rings" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                    </div><br />
                    <div className="row">

                        <div className="col s12 m12 l6">
                            <br /><br /><br /><br /><br />
                            <img src={image9} alt="Magic rings" className="container materialboxed" style={{ width: "100%" }} />
                        </div>
                        <div className="col s12 m12 l6">
                            <h5 className="green-text text-darken-2 center"><b>HISTORY OF MAGIC RINGS</b></h5>
                            <p>Early stories of magical rings date to classical antiquity.Plato in the second book of The Republic, tells a story about the Ring of Gyges, which conferred invisibility on its wearer. Te shepherd Gyges who found in a cave, used its power to seduce the queen, kill the king and take his place. Earlier accounts of Gyges, however, who was the kingof Lydia make no mention of the magic rings. Magic powers are not generally attributes to rings in ancient Greek legend, although many other magical objects are listed, particulary in the Perseus myth.</p>
                            <p>Josephus repeats an an anecdote of one Eleazar who used a magic ring to exorcise demons in the presence of the Vespasian. J.G Frazer , in the study of magic rings can serve, in th "primitive mind", as devices to prevent the soul from leacing a body and to prevent the demons from gaining entry. A magic ring, therefore might confer the immortality by preventing the souls departure nd thwart the penetration of ny harmful magic that might be directed against the wearer. These magical properties inhibiting egress of the soul may explain "an ancient Greek maxim, attributed to (the ancient philosopher and mystic) Pythagoras, which forbade people to wear rings"</p>
                        </div>
                    </div>
                    <div className="grey lighten-5">
                        <h5 className="green-text text-darken-2 center grey lighten-4">FUNCTIONS OF MAGIC RINGS</h5>
                        <div className="row">
                            <div className="col s10 m10 l10 push-l1 offset-s1 push-m1">
                                <div className="row">
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image38} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Ring for Good Luck</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>The ring is fro bringing good luck and helping influence positive outcomes</strong></p>
                                        </div>
                                    </div><div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image39} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Wallet of fortune and Wealth</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>Bring good fortune and influence luck in building wealth in your lifetime.</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image40} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Ring for Success in Business</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>This ring will ensure customers keep flocking into your business non stop.</strong></p>
                                        </div>
                                    </div><div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image41} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Ring for Success in Love.</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>Get a ring and that of your partner in order to bond your relationship for good.</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image42} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Children's Magic Chain for Success</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>This helps a child grow well physically, emotionally and psychologically.</strong></p>
                                        </div>
                                    </div>
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image43} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Hearing Magic Rings</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>These rings are for bringing about physical or emotional healing.</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image44} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Anklet For Black Magics</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>This will protect you home and away from bad omens</strong></p>
                                        </div>
                                    </div>
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image45} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Ring To Remember Answers and Pass Exams</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>The ring is for bringing good luck and helping influence positive outcomes.</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image46} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Ring for Witchcraft/Juju</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>This is for Black Magic Protection throughout your lifetime.</strong></p>
                                        </div>
                                    </div>
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image47} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Bracelet for Beauty</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>Want to look attractive to people? Get a magic bracelet from Dr.Bakongwa.</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image48} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Necklace for Protection</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>Protects from unexpected dangers like accidents, attacks, stray bullets etc.</strong></p>
                                        </div>
                                    </div>
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image49} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Ring for Success Love</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>Get a ring and that of your partner in order to bond your relationship for good.</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image50} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Ring for Strong Love Bond</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>This ring makes love sensations move in you and your partner to the extent of always thinking about your patner. Get yours today.</strong></p>
                                        </div>
                                    </div>
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image51} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Ring for Promotion</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>You get to rise steadily in your career path. You simply wear it while working.s</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image38} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Ring for Winning Cassinos</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>Put on the ring while playing and win lotteries, casino, horses,football bets and many more.</strong></p>
                                        </div>
                                    </div>
                                    <div className="col card s12 m12 l6">
                                        <div className="card-image">
                                            <img src={image9} alt="imagehere" className="container materialboxed" />
                                            <span className="card-tittle green-text text-darken-2"><strong>Magic Ring for Miracles</strong></span>
                                        </div>
                                        <div className="card-content">
                                            <p> <strong>This ring will make you see miracles when least expected. These miracles will be for your own good.</strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="center">
                        <a href="tel:+254 796 703 069" className="btn green darken-2"><i className="material-icons left">phone</i>CALL DOCTOR BAKONGWA NOW</a>
                    </div><br />
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}