import React, { Component } from 'react'
import image0 from './Images/image0.png'

export default class Whatsapp extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="fixed-action-btn">
                    <a href="https://api.whatsapp.com/send?phone=+254 796 703 069" className="btn-floating tooltipped btn-large white" data-position="left" data-tooltip="Chat with Bakongwa Doctors.">
                        <i className="large material-icons green-text darken-2">
                            <img src={image0} alt="whatsappicon" style={{ width: "100%" }} />
                        </i>
                    </a>
                </div>
            </React.Fragment>
        )
    }
}