import React, { Component } from 'react'
import Whatsapp from './components/Whatsaap'
import Navbar from './components/Navbar'
import image79 from './components/Images/image79.jpg'
import Footer from './components/Footer'

export default class FinancialSolution extends Component {

    componentDidMount() {
        document.title = 'Bakongwa Doctors'
    }


    render() {
        return (
            <React.Fragment>
                <Navbar /><br />
                <Whatsapp />
                <div className="row grey lighten-5 row">
                    <div className="col s10 m10 l10 push-l1 push-s1 offset-m1">
                        <img src={image79} alt="image73" className="materialboxed col s12 m12" />
                        <span className="img-tittle green-text text-darken-2"><strong style={{ fontSize: "25px" }}>BLEMISHES SOLUTION AND SCARS ON THE BODY:HAVING SMOOTH AND ATTRACTIVE SKIN.</strong></span>
                        <p>

                            My face was so damaged that I wouldn't leave my house, but now I have healed!

                            My name is Anyango from the Kisumu region. I used to be a model who participated in competitions in this East African region regularly. I didn't want anything to bother me because that would hinder my popularity. I had won titles in Uganda, and as time went by, scars began to appear on my face, greatly affecting my beauty. Most of the time, I stayed in my room without going out.

                            I didn't want anyone to see me, and this also led to my inability to participate in some competitions. For a long time, I lost a lot because I used to earn a lot of money in those competitions.

                            The issue bothered me a lot, and what surprised me was that I had tried every oil to remove the scars, but nothing seemed to work. After a few months, I came across the website www.bakongwadoctors.com, where I found that Bakongwa Doctors is a renowned specialist with skills in solving various problems, including mine.

                            I didn't waste time; I took Bakongwa Doctors' contact details and called him. He invited me to his office and gave me a mixture of herbal medicines, explaining that they would be able to heal the scars that had given me a completely different appearance.

                            In addition to the medicines, he performed his rituals, and I went back home to use the medicines as advised. After three days, I couldn't believe my eyes when I looked at myself in the mirror. The scars were gone; the herbal medicines from Bakongwa Doctors had done the job.

                            I plunged back into my modeling career, and this time I won an award. My beauty was truly captivating, and everyone admired me in every way. I am very grateful to Bakongwa Doctors for the help he gave me. Anyone facing a similar issue can visit Bakongwa Doctors because he has the perfect solution.</p>
                        <strong>Connect with Us:<a href="tel:+254796703069" className="green-text text-darken-2" > Phone : +254796703069</a></strong> | <strong><a href="mailto:bbakongwadoctors@gmail.com" className="green-text text-darken-2" >Email :bbakongwadoctors@gmail.com </a></strong>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}