import React from 'react'
import M from 'materialize-css'
import Navbar from './components/Navbar'
import Footer from './components/Footer'

const Counselling = function (props) {

    React.useEffect(function () {
        document.title = 'Counselling | Bakongwa Doctors'
        M.AutoInit()
        return function () { }
        // eslint-disable-next-line
    }, [])

    return (
        <main>
            <Navbar />
            <div className='row' >
                <div className='col s10 m10 l10 push-l1 push-s1 offset-m1'>
                    <h4 className='center green-text'>Traditional Counselling</h4>
                    <p>
                        Counseling is, in many respects, an unusual area of study, in that it encompasses a set of strongly competing theoretical perspectives, a wide range of practical applications, and meaningful inputs from various social disciplines. The term ‘counseling’ is used to describe a variety of different activities. Most people have different views on what counseling is, and the context of its application, hence the multiplicity of theoretical models. Some people believe that it is a means of giving good advice, teaching on morality, mentoring for initiation, and guidance on marriage and sociocultural issues. Sometimes it relates to information-giving only. All these views are correct. From a conventional perspective, counseling is a process that involves a special type of helping relationship between a counselor and a client that is purposively interactive and ameliorative. The primary aim of counseling is to help people with concerns to reflect on their changed situation, identify possible solutions, and develop action plans to resolve those presenting problems, so that they can continue living resourcefully for personal development and satisfaction.
                        <br /><br />
                        People in all societies, at all times, have experienced emotional or psychological distress and behavioral problems; and in each culture, there have been well-established indigenous ways of helping people to deal with these difficulties. Indigenous ways have existed for the whole of human creation, and they aptly connote traditional ways of doing things, based on traditional systems of knowledge and cultural practices. People in different cultures and societies globally have distinct ways of assisting those experiencing social, psychological, behavioral, emotional, familial, spiritual, and physical, problems. Traditional counseling is closely linked to indigenous ways of doing things which were based extensively on oral history, that is, traditional knowledge and social skills pertaining to some critical issues, customs, or practices, were handed down from one successive generation to another, orally. Oral history as a source and method has been central to the development of African historiography, and no element has served as a clearer signature of, and for, the traditional oral source, within the programs of recovering the African past
                        <br /><br />
                        The words ‘traditional’ and ‘indigenous’ are used in the context of the original experience of the sacred, cultivated by the African people, and the concrete expression of that experience within the different ecological and socio-historical settings [9]. Being traditional does not mean static or unchangeable, but rather, in keeping with the original sense of the term, it means that the living experience of cultural customs and practices is handed down from one successive generation to another in perpetuity. These reflect the period, circumstance and cultural factors surrounding a particular generation or human society, which acts as a reference point. Tradition and culture are indigenous entities, they are dynamic, and in constant flux. Traditional counseling has always been part of the fabric of all human cultures and societies, present in the very midst of peoples and communities worldwide, from time immemorial. Like in the past, it takes place within the kinship system, family and community.
                        <br /><br />
                        Traditional counseling is beset by cultural nuances: it takes place in the local environment, it is offered by the local people, it reflects a mirror of the society in which it is offered, and it has always been part of the human experience since ancient times. This analysis of traditional counseling has a global interpretation and application as well. Most people globally practice some forms of traditional counseling, based on their sociocultural context, and the nature of presenting problems. It is in the context of this understanding that it becomes imperative to promote and strengthen indigenous approaches of traditional counseling that are responsive to the social life and expectations of the local people.
                        <br /><br />
                        Traditional counseling applies indigenous forms of helping people experiencing various problem situations, and those that wish to make a transitional commitment, such as initiation at puberty, or marriage, or are aggrieved through loss of a beloved one, due to physical illness, psychosomatic dysfunction, or cardiac failure, injury, or accident. Forms of traditional counseling refer to the various methods or approaches used by traditional counselors during the process of their interaction with clients in a culturally acceptable environment, using appropriate interventions that are tailored to meet their needs, expectations, and aspirations. The commonly practiced traditional counseling approaches or methods are: marriage counseling, pastoral counseling, adherence counseling, initiation counseling, family counseling, community counseling, and bereavement counseling.
                        <br /><br />
                        when you encounter any kind of challenge or when you need any kind of support, please do not hesitate to find a doctor because he will listen to you and give you help as soon as possible, send us an email to <a href='mailto:bbakongwadoctors@gmail.com'>bbakongwadoctors@gmail.com</a>, talk to the doctor via whatsapp <a href='https://wa.me://+254796703069'>+254796703069</a> or visit this page <a href='https://bakongwadoctors/contactus'>BAKONGWA</a> and fill in the information your correct information in the form there and the doctor will find you and serve you more easily.
                    </p>
                </div>
            </div>
            <Footer />
        </main>
    )
}

export default Counselling